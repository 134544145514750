import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const TermsandConditions = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Terms and Conditions - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Terms & Conditions
                    </h2>
                </div>
            </section>

            {/* Simple Content Section */}
            <section className="simpleContentSection overflow-hidden mb-53">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <p>
                                For the entirety of this document “we”, “our”, “us” and “Moonshot
                                Animations” refer to Moonshot Animations that are the owners of this
                                site and their associate that deliver services to you are subject to
                                given terms and conditions. When you visit our website or buy our
                                product/service, you agree to these terms and conditions. Kindly refer
                                to the below carefully:
                            </p>

                            <h2>Acceptance of Terms</h2>
                            <p>
                                When you visit Moonshot Animations (The website) you agree to abide by
                                the terms and conditions and you consent to our privacy policy. You
                                agree that you will not be making use of this website for unlawful
                                purposes and would bear in mind all the laws and regulations. You agree
                                to not use this website in a way that may impact its performance,
                                corrupt the content or in any way hinder the functionality of this
                                website. You also agree to not compromise the safety of this website or
                                trying to enter restricted areas of this website.
                            </p>
                            <p>
                                You consent to be entirely responsible for any claim, liability,
                                expense, costs and losses that include legal fees by us which starts
                                from violation of the mentioned terms and conditions.
                            </p>

                            <h2>Modification</h2>
                            <p>
                                We reserve the right to change any part of this document without any
                                prior notice and your usage of our website would amount to your consent
                                to these terms and conditions. We encourage our visitors to constantly
                                check this document. Moonshot Animations reserves the full right to
                                alter or erase any part of this site without warning or liability that
                                sparks from that action.
                            </p>

                            <h2>Limitation of Liability</h2>
                            <p>
                                Moonshot Animations would not be held responsible under any conditions
                                for indirect, unique or consequential damages that include any business,
                                profit, revenue or data loss linked with your use of our website or any
                                service/product provided by us. We will not be held responsible under
                                any conditions for the death or any personal injury. If you are not
                                comfortable or satisfied with any of our material or our terms and
                                conditions, you should leave right now and stop using our services. We
                                strive to ensure that our website is virus-free, we however don’t
                                guarantee the absence of all malwares and viruses and we also don’t give
                                any guarantees regarding e-mail attachments that you might have received
                                from third parties. The recipients are encouraged to check email,
                                attachments and other files before accessing them.
                            </p>

                            <h2>Loss</h2>
                            <p>
                                We are not responsible for any lost file; you are advised to always keep
                                an original copy of your work.
                            </p>

                            <h2>Prices</h2>
                            <p>
                                Prices included on the website are just estimates that are liable to
                                change without prior notice. You are encouraged to get a definite quote
                                before you submit your project.
                            </p>

                            <h2>Payment</h2>
                            <p>
                                Payment is to be made in full in advance. Payment plans are applicable
                                on projects costing over $4000.
                            </p>

                            <h2>Warranty</h2>
                            <p>
                                Both the parties warrant their power to enter this agreement and gained
                                the approval to do so.
                            </p>

                            <h2>Money Back Guarantee</h2>
                            <p>
                                We are extremely confident of the work we do which is why we back it up
                                with a money back guarantee. If we are unable to meet and beat your
                                expectations, we will give you a refund.
                            </p>
                            <p>Variations to this Service</p>
                            <p>
                                We are authorized to fluctuate the prices resulting from any of the
                                below mentioned factors:
                            </p>
                            <ul>
                                <li>1 – Any alterations in your instructions.</li>
                                <li>2 – If you forgot to mention the complete instructions.</li>
                                <li>
                                    3 – Interruptions, delays or overtime for reason beyond the control of
                                    Moonshot Animations.
                                </li>
                            </ul>

                            <h2>Cancellation</h2>
                            <p>
                                You have the right to cancel your work any time before we have started
                                working on it. If you wish to stop the work once we have started working
                                on it, you would be charged for the work that has been completed till
                                then. The half-completed work will also be delivered to you upon
                                cancellation.<br />
                                All cancellation/refund requests will become null &amp; void once the
                                customer chooses to revise or approve the initial storyboard design.
                            </p>

                            <h2>Contract Termination</h2>
                            <p>
                                We reserve the right to terminate any assignment for any reason without
                                any prior notice.
                            </p>

                            <h2>Copyright</h2>
                            <p>
                                Client has 100% ownership of the product delivered to him by the
                                company. The person signing the Contract on behalf of the Client
                                confirms that they have the requisite power and authority to enable them
                                to enter into and perform this Contract and further confirms that they
                                accept full responsibility for the payment of fees. The Client shall be
                                liable to pay to the Agency, on demand, all reasonable costs, charges or
                                losses sustained or incurred by the Artist and the Agency arising
                                directly or indirectly from the Client’s fraud, negligence, failure to
                                perform or delay in the performance of any of its obligations under this
                                Contract, subject to the Agency confirming such costs, charges and
                                losses to the Client in writing.
                            </p>

                            <h2>Third Parties</h2>
                            <p>
                                This website might include hyperlinks to third party sites. We do not
                                endorse such websites nor take any responsibility for it. We are not
                                liable for the content displayed by these sites. Such sites are only
                                advertised for your convenience and ease. These third-party websites,
                                their services and products are neither endorsed nor linked to our
                                website.
                            </p>

                            <h2>Severance</h2>
                            <p>
                                In case, any part of this document is considered void or unenforceable,
                                the remaining policy would still stay in effect.
                            </p>

                            <h2>Force Majeure</h2>
                            <p>
                                Both parties would be free from their duties in the event of national
                                emergency, war, governmental regulations or any other reasons both
                                parties cannot control.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default TermsandConditions;