import React, { useState } from "react";
import VideoModal from "../shared/videoModal"
import Header from "../shared/header";
import Footer from "../shared/footer";
import ContactForm from "../shared/contactForm";
import portfolioImg2D1 from "../../images/portfolio/two-d-p1.png";
import portfolioImg2D2 from "../../images/portfolio/two-d-p2.png";
import portfolioImg2D3 from "../../images/portfolio/two-d-p3.png";
import portfolioImg2D4 from "../../images/portfolio/two-d-p4.png";
import portfolioImg2D5 from "../../images/portfolio/two-d-p5.png";
import portfolioImg2D6 from "../../images/portfolio/two-d-p6.png";
import portfolioImg2D7 from "../../images/portfolio/two-d-p7.jpg";
import portfolioImg3D1 from "../../images/portfolio/threeD-p1.jpg";
import portfolioImg3D2 from "../../images/portfolio/threeD-p2.jpg";
import portfolioImg3D3 from "../../images/portfolio/threeD-p3.jpg";
import portfolioImg3D4 from "../../images/portfolio/threeD-p4.jpg";
import portfolioImg3D5 from "../../images/portfolio/threeD-p5.jpg";
import portfolioImg3D6 from "../../images/portfolio/threeD-p6.jpg";
import portfolioImg3D7 from "../../images/portfolio/threeD-p7.jpg";
import portfolioImg3D8 from "../../images/portfolio/threeD-p8.jpg";
import portfolioImg3D9 from "../../images/portfolio/threeD-p9.jpg";
import portfolioImg3D10 from "../../images/portfolio/threeD-p10.jpg";
import portfolioImg3D11 from "../../images/portfolio/threeD-p11.jpg";
import portfolioImg3D12 from "../../images/portfolio/threeD-p12.jpg";
import portfolioImg3D13 from "../../images/portfolio/threeD-p13.jpg";
import portfolioImg3D14 from "../../images/portfolio/threeD-p14.jpg";
import portfolioImgVideo1 from "../../images/portfolio/video-p1.jpg";
import portfolioImgVideo2 from "../../images/portfolio/video-p2.jpg";
import portfolioImgVideo3 from "../../images/portfolio/video-p3.jpg";
import portfolioImgVideo4 from "../../images/portfolio/video-p4.jpg";
import portfolioImgVideo5 from "../../images/portfolio/video-p5.jpg";
import portfolioImgLogo1 from "../../images/portfolio/logo-p1.jpg";
import portfolioImgLogo2 from "../../images/portfolio/logo-p2.jpg";
import portfolioImgLogo3 from "../../images/portfolio/logo-p3.jpg";
import portfolioImgLogo4 from "../../images/portfolio/logo-p4.jpg";
import portfolioImgLogo5 from "../../images/portfolio/logo-p5.jpg";
import portfolioImgMotion1 from "../../images/portfolio/motion-p1.jpg";
import portfolioImgMotion2 from "../../images/portfolio/motion-p2.jpg";
import portfolioImgMotion3 from "../../images/portfolio/motion-p3.jpg";
import playIcon from "../../images/play.png";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const Portfolio = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const [dataSrc, setDataSrc] = useState('');
    const openModel = (src) => {
        setVideoModalIsOpen(true);
        setDataSrc(src);
    }
    const closeModel = () => {
        setVideoModalIsOpen(false);
    }
    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Our Portfolio - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <VideoModal

                show={videoModalIsOpen}
                onCancel={closeModel}
                dataSrc={dataSrc}
            />
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient1"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Portfolio
                    </h2>
                </div>
                <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                >
                    We’ve worked on each project with dedication and a commitment to
                    excellence. We’ll go to the moon and back to deliver the best.
                </p>
            </section>

            {/* Portfolio Section */}
            <section className="priceSection PortfolioSectionScreen position-relative">
                <div className="container">
                    <nav>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="11"
                                    data-bs-toggle="pill"
                                    data-bs-target="#DesignAnimation"
                                    type="button"
                                    role="tab"
                                    aria-controls="DesignAnimation"
                                    aria-selected="true"
                                >
                                    Design Animation
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="22"
                                    data-bs-toggle="pill"
                                    data-bs-target="#TwoDAnimation"
                                    type="button"
                                    role="tab"
                                    aria-controls="TwoDAnimation"
                                    aria-selected="false"
                                >
                                    2D Animation
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="33"
                                    data-bs-toggle="pill"
                                    data-bs-target="#VideoEditing"
                                    type="button"
                                    role="tab"
                                    aria-controls="VideoEditing"
                                    aria-selected="false"
                                >
                                    Video Editing
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="44"
                                    data-bs-toggle="pill"
                                    data-bs-target="#ThreeDAnimation"
                                    type="button"
                                    role="tab"
                                    aria-controls="ThreeDAnimation"
                                    aria-selected="false"
                                >
                                    3D Animation
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="55"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Whiteboard"
                                    type="button"
                                    role="tab"
                                    aria-controls="Whiteboard"
                                    aria-selected="false"
                                >
                                    Whiteboard/Motion Graphics
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div className="tab-content mt-100" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active p-0 portfolioItem"
                            id="DesignAnimation"
                            role="tabpanel"
                            aria-labelledby="DesignAnimation-tab"
                        >
                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/575892746?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgLogo1} alt="portfolioImgLogo1" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Logo Animation</h3>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574555773?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgLogo2} alt="portfolioImgLogo2" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Logo Animation</h3>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574555196?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgLogo3} alt="portfolioImgLogo3" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Logo Animation</h3>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583171643?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgLogo4} alt="portfolioImgLogo4" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Logo Intro Animation</h3>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583171681?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgLogo5} alt="portfolioImgLogo5" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Logo Ident</h3>
                                </div>


                            </div>
                        </div>

                        <div
                            className="tab-pane fade p-0 portfolioItem"
                            id="TwoDAnimation"
                            role="tabpanel"
                            aria-labelledby="TwoDAnimation-tab"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574555418?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D1} alt="portfolioImg2D1" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Explainer Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/598974724?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D2} alt="portfolioImg2D2" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Character Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/596586868?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D3} alt="portfolioImg2D3" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Manassah 2D Animation Promo</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574532977?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D4} alt="portfolioImg2D4" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Explainer Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/578613983?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D5} alt="portfolioImg2D5" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Storyboard Reel</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/582249826?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D6} alt="portfolioImg2D6" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Animation Process</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583171595?h=e11d5dee40&title=0&byline=0&portrait=0")}
                                    >
                                        <img src={portfolioImg2D7} alt="portfolioImg2D7" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>2D Animated Music Video</h3>
                                </div>

                            </div>
                        </div>

                        <div
                            className="tab-pane fade  p-0 portfolioItem"
                            id="VideoEditing"
                            role="tabpanel"
                            aria-labelledby="VideoEditing-tab"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/642062471?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgVideo1} alt="portfolioImgVideo1" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Music Video Editing</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/642059736?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgVideo2} alt="portfolioImgVideo2" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Music Video Editing</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/612937694?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgVideo3} alt="portfolioImgVideo3" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Fashion Video Editing</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/612959435?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgVideo4} alt="portfolioImgVideo4" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Neutrax - Natural Wonder</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/586993068?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgVideo5} alt="portfolioImgVideo5" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Explainer</h3>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tab-pane fade p-0 portfolioItem"
                            id="ThreeDAnimation"
                            role="tabpanel"
                            aria-labelledby="ThreeDAnimation-tab"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574554355?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D1} alt="portfolioImg3D1" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Logo Ident Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574511885?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D2} alt="portfolioImg3D2" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Animation - Commercial</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/580867555?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D3} alt="portfolioImg3D3" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Modelling Reel</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/580483304?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D4} alt="portfolioImg3D4" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Vending Machine</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/575009895?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D5} alt="portfolioImg3D5" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Big Screen Animation Production Process</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/575009705?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D6} alt="portfolioImg3D6" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Motion Capture Production Process</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/575009486?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D7} alt="portfolioImg3D7" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Game Animation Production</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583181878?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D8} alt="portfolioImg3D8" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Product Explainer</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583182710?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D9} alt="portfolioImg3D9" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Ashtray 3D Commercial</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583182780?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D10} alt="portfolioImg3D10" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Ashtray 3D Commercial</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583442327?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D11} alt="portfolioImg3D11" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Rigging Reel</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583442829?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D12} alt="portfolioImg3D12" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Fight Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/583598192?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D13} alt="portfolioImg3D13" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Product Animation</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/586993938?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImg3D14} alt="portfolioImg3D14" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>3D Character Explainer</h3>
                                </div>

                            </div>
                        </div>

                        <div
                            className="tab-pane fade  p-0 portfolioItem"
                            id="Whiteboard"
                            role="tabpanel"
                            aria-labelledby="Whiteboard-tab"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/574553252?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgMotion1} alt="portfolioImgMotion1" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>App Explainer Video</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/631066878?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgMotion2} alt="portfolioImgMotion2" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Revivify - Promotional Video</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-xs-12">
                                    <div
                                        className="video mb-28"
                                        onClick={() => openModel("https://player.vimeo.com/video/631067486?h=becf574b47?autoplay=1&amp;modestbranding=1&amp;showinfo=0")}
                                    >
                                        <img src={portfolioImgMotion3} alt="portfolioImgMotion3" className="img-fluid" />
                                        <div className="middle">
                                            <img src={playIcon} alt="play" />
                                        </div>
                                    </div>
                                    <h3>Glisten Serum - Promotional Video</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Form Section */}
            <ContactForm />

            <Footer />
        </div>
    )
}
export default Portfolio;