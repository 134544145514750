import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { send } from 'emailjs-com';
import { Config } from "../../util/config";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import history from "../../history";
import MOdalImg1 from "../../images/contactformbanner.png";
import Services from '../../services/index';
const QueryModal = (props) => {

    const { show, onCancel } = props;
    const closeModel = () => {
        onCancel();
    }

    const [successMsg, setSuccessMsg] = useState("");
    const getValidationSchema = () => {
        setSuccessMsg("");
        return Yup.object().shape({
            fullname: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),

            phonenumber: Yup.string()
                .min(2, 'Too Short!')
                .max(20, 'Too Long!')
                .required('Required'),

            email: Yup.string()
                .email('Invalid email')
                .required('Required'),

            serviceItems: Yup.array()
                .min(1, "Required at least one")
        });
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        // let res = await Services.sendQuickResponseEmail(values.fullname, values.email, values.phonenumber, values.serviceItems);

        // if (res.response == "Success") {
        //     history.push('/thankyou')
        //     closeModel()
        // } else {
        //     setSuccessMsg("Failed");
        // }
        
        history.push('/thankyou')
        closeModel()

        // const toSend = {
        //     fullname: values.fullname,
        //     email: values.email,
        //     phonenumber: values.phonenumber,
        //     serviceItems: values.serviceItems,
        // }
        // send(
        //     Config.serviceId,
        //     Config.templateId,
        //     toSend,
        //     Config.userId
        // )
        //     .then((response) => {
        //         console.log('SUCCESS!', response.status, response.text);
        //         history.push('/thankyou')
        //         closeModel()
        //     })
        //     .catch((err) => {
        //         console.log('FAILED...', err);
        //         setSuccessMsg("/Failed");
        //     });
        resetForm();
        setSuccessMsg("Thank you! Your request has been successfully submitted.");
        setSubmitting(false);
    }

    return (

        <Modal
            show={show}
            onHide={onCancel}
            className="border-radius-none"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="talkteam"
        >
            {/* <div
            className="modal fade"
            id="talkteam"
            tabIndex="-1"
            aria-labelledby="talkteam"
            style={{ display: 'none' }}
            aria-hidden="true"
        > */}
            {/* <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content"> */}
            <div className="modal-body p-0">
                <div className="container-fluid">
                    {/* <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >

                                <i className="bi bi-x-lg text-white"></i>
                            </button> */}
                    <button
                        type="button"
                        className="btn-close"
                        onClick={closeModel}
                    >
                        <i className="bi bi-x-lg text-white"></i>
                    </button>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 p-0 d-none d-sm-block">
                            <img src={MOdalImg1} className="img-fluid" alt="Motal Img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 formContainer">
                            <h2>Talk to Our Team Today</h2>
                            <p>
                                If you have any questions about our services, or want to discuss your
                                project, shoot us an email at
                            </p>


                            <Formik
                                initialValues={{
                                    fullname: '',
                                    email: '',
                                    phonenumber: '',
                                    serviceItems: []
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={getValidationSchema}
                            >
                                {({ errors, touched }) => (
                                    <Form id="QueryForm">

                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                className={errors.fullname && touched.fullname ? "form-control error" : "form-control"}
                                                id="fullname"
                                                name="fullname"
                                                placeholder="Your Full Name"
                                            />
                                            <label htmlFor="fullname">Your Name</label>
                                            {errors.fullname && touched.fullname ? (
                                                <span className="errorMsg">{" * " + errors.fullname}</span>
                                            ) : null}
                                        </div>

                                        <div className="form-floating">
                                            <Field
                                                type="email"
                                                className={errors.fullname && touched.fullname ? "form-control error" : "form-control"}
                                                id="email"
                                                name="email"
                                                placeholder="Your Email Address"
                                            />
                                            <label htmlFor="email">Your Email Address</label>
                                            {errors.email && touched.email ? (
                                                <span className="errorMsg">{" * " + errors.email}</span>
                                            ) : null}
                                        </div>

                                        <div className="form-floating">
                                            <Field
                                                type="tel"
                                                className={errors.fullname && touched.fullname ? "form-control error" : "form-control"}
                                                id="phonenumber"
                                                name="phonenumber"
                                                placeholder="Phone Number"
                                            />
                                            <label htmlFor="phonenumber">Phone Number</label>
                                            {errors.phonenumber && touched.phonenumber ? (
                                                <span className="errorMsg">{" * " + errors.phonenumber}</span>
                                            ) : null}
                                        </div>

                                        <h4>What Services You Want?</h4>

                                        <div className="checkListItems">
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="2D Animation" id="1" />
                                                <label className="form-check-label" htmlFor="1"> 2D Animation </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="3D Animation" id="2" />
                                                <label className="form-check-label" htmlFor="2"> 3D Animation </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Motion Graphics" id="3" />
                                                <label className="form-check-label" htmlFor="3"> Motion Graphics </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Promotional Video" id="4" />
                                                <label className="form-check-label" htmlFor="4"> Promotional Video </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Video Editing" id="5" />
                                                <label className="form-check-label" htmlFor="5"> Video Editing </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Explainer Video" id="6" />
                                                <label className="form-check-label" htmlFor="6"> Explainer Video </label>
                                            </div>

                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Logo Design Animation" id="7" />
                                                <label className="form-check-label" htmlFor="7">
                                                    Logo Design Animation
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="White Board Animation" id="8" />
                                                <label className="form-check-label" htmlFor="8">
                                                    White Board Animation
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <Field className="form-check-input" type="checkbox" name="serviceItems" value="Other Projects" id="9" />
                                                <label className="form-check-label" htmlFor="9"> Other Projects </label>
                                            </div>
                                            {errors.serviceItems && touched.serviceItems ? (
                                                <span className="errorMsg">{" * " + errors.serviceItems}</span>
                                            ) : null}
                                        </div>

                                        <div className="d-grid gap-2 mt-2 mb-2">
                                            <span>
                                                <button type="submit"
                                                    className="
                                                                btn
                                                                btnGradientOne
                                                                d-flex
                                                                justify-content-center
                                                                modalbutton
                                                            "
                                                >Get Started </button>
                                            </span>
                                            <span className="successMsg">
                                                {
                                                    successMsg
                                                }
                                            </span>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
            {/* </div> */}
        </Modal>
    )
}
export default QueryModal;