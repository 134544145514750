import React, { useState } from "react";
import history from "../../history";
import QueryModal from "../shared/queryModal";
import logo from "../../images/logo.png";
import menuIcon1 from "../../images/menu-icon1.png";
import menuIcon2 from "../../images/menu-icon2.png";
import menuIcon3 from "../../images/menu-icon3.png";
import menuIcon4 from "../../images/menu-icon4.png";
import menuIcon5 from "../../images/menu-icon5.png";
import menuIcon6 from "../../images/menu-icon6.png";
import menuIcon7 from "../../images/menu-icon7.png";
import menuIcon8 from "../../images/menu-icon8.png";

const Header = () => {

    const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);

    const openModel = () => {
        setQueryModalIsOpen(true);
    }

    const closeModel = () => {
        setQueryModalIsOpen(false);
    }


    return (
        <header className="site-header fixed-top" id="header">
            <div className="callUsSection text-center">
                <div className="container">
                    <ul>
                        <li>
                            <a href="tel:+17327883030"> <i className="bi bi-telephone"></i> Call Us </a>
                        </li>
                        <li>
                            <a href="mailto:info@moonshotanimations.com">
                                <i className="bi bi-chat-right-text"></i> Let's Connect
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container">
                    <a className="navbar-brand" onClick={() => history.push("/")} >
                        <img src={logo} alt="Moonshot logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse justify-content-right"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" onClick={() => history.push("/why-moonshot")}
                                >Why Moonshot?</a
                                >
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" 
                                    data-bs-toggle="collapse"
                                    data-bs-target="#serviceDropdown"
                                    aria-controls="serviceDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    Our Services
                                </a>
                                <ul id="serviceDropdown" className="dropdown-menu serviceDropdown">
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/2d-animation")}>
                                            <img src={menuIcon1} alt="menu icon" /> 2D Animation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/logo-design-animation")}>
                                            <img src={menuIcon5} alt="menu icon" />Logo Design Animation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/3d-animation")}>
                                            <img src={menuIcon2} alt="menu icon" />3D Animation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/explainer-video")}>
                                            <img src={menuIcon6} alt="menu icon" />Explainer Video
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/white-board-animation")}>
                                            <img src={menuIcon3} alt="menu icon" />White Board Animation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/promotional-video")}>
                                            <img src={menuIcon7} alt="menu icon" />Promotional Video
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/motion-graphics")}>
                                            <img src={menuIcon4} alt="menu icon" />Motion Graphics
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={() => history.push("/our-services/video-editing")}>
                                            <img src={menuIcon8} alt="menu icon" />Video Editing
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/portfolio")}>Portfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-process")}>Our Process</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/packages")}>Packages</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-client")}>Our Clients</a>
                            </li>

                            <li className="nav-item">
                                <button className="btn"
                                    // data-bs-toggle="modal"
                                    //     data-bs-target="#talkteam"
                                    onClick={() => openModel()}
                                >Get a free proposal</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <QueryModal
                show={queryModalIsOpen}
                onCancel={closeModel}
            />
        </header>
    )
}
export default Header;