import React, { useState } from "react";
import { send } from 'emailjs-com';
import { Config } from "../../util/config";
import { Formik, Form, Field } from 'formik';
import history from "../../history";
import * as Yup from 'yup';

import Services from '../../services/index';

const ContactForm = () => {

    const [successMsg, setSuccessMsg] = useState("");
    const getValidationSchema = () => {
        setSuccessMsg("");
        return Yup.object().shape({
            fullname: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),

            phonenumber: Yup.string()
                .min(2, 'Too Short!')
                .max(20, 'Too Long!')
                .required('Required'),

            email: Yup.string()
                .email('Invalid email')
                .required('Required'),

            serviceItems: Yup.array()
                .min(1, "Required at least one")
        });
    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        //let res = await Services.sendEmail(values.fullname, values.email, values.phonenumber, values.serviceItems, values.projectDetail);

        //if (res.response == "Success") {
        //    history.push("/thankyou")
        //} else {
            //setSuccessMsg("Failed");
         //   history.push("/thankyou")
       // }
        history.push("/thankyou")
        // const toSend = {
        //     fullname: values.fullname,
        //     email: values.email,
        //     phonenumber: values.phonenumber,
        //     serviceItems: values.serviceItems,
        //     projectDetail: values.projectDetail
        // }
        // send(
        //     Config.serviceId,
        //     Config.contactFormTemplateId,
        //     toSend,
        //     Config.userId
        // )
        //     .then((response) => {
        //         console.log('SUCCESS!', response.status, response.text);
        //         history.push("/thankyou")
        //     })
        //     .catch((err) => {
        //         console.log('FAILED...', err);
        //         setSuccessMsg("Failed");
        //     });
        resetForm();
        setSuccessMsg("Thank you! Your request has been successfully submitted.");

        setSubmitting(false);
    }
    return (
        <section className="contact-modal position-relative">
            <div className="container">
                <div
                    className="contactBox"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1200"
                >
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 p-0 d-none d-sm-block">
                            <h2>Talk to Our Team Today</h2>
                            <p>
                                If you have any questions about our services, or want to discuss your
                                project, shoot us an email at
                            </p>
                            <p>
                                <a href="mailto:info@moonshotanimations.com"
                                >info@moonshotanimations.com</a
                                >
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6 formContainer">


                            <Formik
                                initialValues={{
                                    fullname: '',
                                    email: '',
                                    phonenumber: '',
                                    serviceItems: [],
                                    projectDetail: ''
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={getValidationSchema}
                            >
                                {({ errors, touched }) => (
                                    <Form id="contactUsForm">
                                        <div className="form-floating mb-3">
                                            <Field
                                                type="text"
                                                className={errors.fullname && touched.fullname ? "form-control error" : "form-control"}
                                                id="fullname"
                                                name="fullname"
                                                placeholder="Your Full Name"
                                            />
                                            <label htmlFor="fullname">Your Name</label>
                                            {errors.fullname && touched.fullname ? (
                                                <span className="errorMsg">{" * " + errors.fullname}</span>
                                            ) : null}
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field
                                                type="email"
                                                className={errors.email && touched.email ? "form-control error" : "form-control"}
                                                id="email"
                                                name="email"
                                                placeholder="Your Email Address"
                                            />
                                            <label htmlFor="email">Your Email Address</label>
                                            {errors.email && touched.email ? (
                                                <span className="errorMsg">{" * " + errors.email}</span>
                                            ) : null}
                                        </div>

                                        <div className="form-floating mb-3">
                                            <Field
                                                type="tel"
                                                className={errors.phonenumber && touched.phonenumber ? "form-control error" : "form-control"}
                                                id="phonenumber"
                                                name="phonenumber"
                                                placeholder="Your Email Address"
                                            />
                                            <label htmlFor="phonenumber">Phone Number</label>
                                            {errors.phonenumber && touched.phonenumber ? (
                                                <span className="errorMsg">{" * " + errors.phonenumber}</span>
                                            ) : null}
                                        </div>

                                        <h4>What Services You Want?</h4>

                                        <div className="row mb-4" role="group" aria-labelledby="checkbox-group">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="2D Animation" id="1" />
                                                    <label className="form-check-label" htmlFor="1"> 2D Animation </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="3D Animation" id="2" />
                                                    <label className="form-check-label" htmlFor="2"> 3D Animation </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="White Board Animation" id="3" />
                                                    <label className="form-check-label" htmlFor="3">
                                                        White Board Animation
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Promotional Video" id="4" />
                                                    <label className="form-check-label" htmlFor="4"> Promotional Video </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Other Projects" id="5" />
                                                    <label className="form-check-label" htmlFor="5"> Other Projects </label>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Logo Design Animation" id="6" />
                                                    <label className="form-check-label" htmlFor="6">
                                                        Logo Design Animation
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Motion Graphics" id="7" />
                                                    <label className="form-check-label" htmlFor="7"> Motion Graphics </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Explainer Video" id="8" />
                                                    <label className="form-check-label" htmlFor="8"> Explainer Video </label>
                                                </div>
                                                <div className="form-check">
                                                    <Field className="form-check-input" type="checkbox" name="serviceItems" value="Video Editing" id="9" />
                                                    <label className="form-check-label" htmlFor="9"> Video Editing </label>
                                                </div>
                                                {errors.serviceItems && touched.serviceItems ? (
                                                    <span className="errorMsg">{" * " + errors.serviceItems}</span>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <Field
                                                as="textarea"
                                                rows="10"
                                                className="form-control"
                                                placeholder="Project Detail (Optional)"
                                                name="projectDetail"
                                                id="projectDetail"
                                            ></Field>
                                            <label htmlFor="projectDetail">Project Detail (Optional)</label>
                                        </div>

                                        <div className="position-relative z-index-1">
                                            <button className="form-submit mb-53" type="submit">Submit</button>
                                            <span className="successMsg">
                                                {
                                                    successMsg
                                                }
                                            </span>
                                        </div>

                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContactForm;