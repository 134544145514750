import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Header from "../shared/header";
import Footer from "../shared/footer";
import CheckoutForm from "../shared/orderForm";
import history from "../../history";
import checkImg from "../../images/check.png";
import phoneIcon from "../../images/phone.png";

const promise = loadStripe("pk_live_wmkYbXVtyzrZy1M9YOwpmcWL");

const CheckOut = (props) => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    const {
        category,
        itemList,
        name,
        price
    } = props.location.state.item;

    return (
        <div className="site-wrapper">
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient4"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Check Out
                    </h2>
                </div>
            </section>

            {/*  Package Check Out Setion */}
            <section className="pricePackage mb-100 mt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-md-8 col-sm-8 col-lg-8 col-xs-12">
                            <div className="row">
                                <div className="col-xl-5 col-md-5 col-sm-5 col-lg-5 col-xs-12 pr-30">
                                    <div className="position-relative">
                                        <div className="basicShot1">
                                            <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                                {name}
                                            </h4>
                                            <h3 className="text-center">{price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        </div>
                                    </div>
                                    <button className="btn btnshot" onClick={() => { history.goBack() }}>Cancel</button>
                                </div>
                                <div className="col-xl-7 col-md-7 col-sm-7 col-lg-7 col-xs-12">
                                    <ul className="shotLinks">
                                        {
                                            itemList.map((item, i) => {
                                                return <li>
                                                    <img key={i} src={checkImg} alt="check Img" /><span className="ps-2"
                                                    >{item}</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row phoneDetail">
                                <div className="col-xl-5 col-md-5 col-sm-5 col-lg-5 col-xs-12">
                                    <h3>Need Assistance?</h3>
                                </div>
                                <div
                                    className="
										col-xl-7 col-md-7 col-sm-7 col-lg-7 col-xs-12
										d-flex
										align-items-center
									"
                                >
                                    <div className="d-flex">
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center"
                                        >
                                            <img src={phoneIcon} alt="phone" />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h4>TOLL FREE:</h4>
                                            <h3>+1-732-788-3030</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-4 col-lg-4 col-xs-12">
                            <div className="form-package">
                                <div className="bg-image"></div>

                                <Elements stripe={promise}>
                                    <CheckoutForm data={props.location.state.item} />
                                </Elements>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default CheckOut;