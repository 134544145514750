import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import error404Icon from "../../images/page-not-found.png";

const Error404 = () => {

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden error404Page">
                <div className="text-center">
                    <img src={error404Icon} className="checkGifIcon" alt="404" />
                    <h2
                        className="mainHeading varient4"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Opps! Page Not Found
                    </h2>
                </div>
                <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                >
                    Sorry the page you are looking for does not exit.
                </p>
                <div className="mb-53">
                    <a
                        className="btn btnGradientOne"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        href="/"
                    >Return to Home</a>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default Error404;