import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Refund Policy - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Refund Policy
                    </h2>
                </div>
            </section>

            {/* Simple Content Section */}
            <section className="simpleContentSection overflow-hidden mb-53">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <h2>Cancellation</h2>
                            <p>
                                Company will terminate any project, regardless of the circumstances.
                            </p>
                            <ul>
                                <li>
                                    Client can place any project on hold for a period of a month, if the
                                    client is unreachable after a month of hold, the project will be
                                    cancelled and will not be eligible for refund.
                                </li>
                                <li>A project cannot be cancelled after 3 revision requests.</li>
                                <li>Refund is requested.</li>
                                <li>A fraudulent card has been used to pay for the project.</li>
                            </ul>
                            <p>
                                However, the client can request for a refund at any point of time, they
                                deem fit.
                            </p>

                            <h2>Refund Policy</h2>
                            <p>
                                Moonshot Animations will provide a 100% refund to any client whose
                                project has been been initiated within 10 days of purchase, however,
                                this policy will be voided at any point of time inconsiderate of the
                                situation. Furthermore, if the initial concept has been shared and a
                                refund request is made, the refund will hence be prorated (less the
                                processing fee and production cost incurred). Furthermore, refund will
                                become null &amp; void if:
                            </p>
                            <p>
                                Any request made for revisions in the design phase will be considered as
                                a continuation of the project &amp; a refund request will not be
                                considered.
                            </p>
                            <ul>
                                <li>
                                    The project has been marked as a rush delivery service &amp; the
                                    initial storyboard design has been created.
                                </li>
                                <li>Filed a dispute.</li>
                                <li>Project is outsourced by a third party.</li>
                                <li>Issues unrelated to the company and project.</li>
                            </ul>
                            <ul>
                                <li>Disagreement with their business partner, change of mind</li>
                            </ul>
                            <p>
                                If the client has received his refund, they will not be entitled to use
                                any concept or design shared with the client, if found to be in use by
                                the client under any circumstances, the client will be dealt legally
                            </p>
                            <p>Duplicate payments will be 100% refunded, without any delays.</p>

                            <h2>For Cancellation Refund Claim</h2>
                            <p>To have your refund, follow the following steps:</p>
                            <ul>
                                <li>
                                    Dialing our Toll-Free Number #
                                    <strong><a href="tel:+1-732-788-3014">1-732-788-3014</a></strong>
                                </li>
                            </ul>
                            <ul>
                                <li>Live Chat. Click here to start chat now</li>
                            </ul>
                            <ul>
                                <li>
                                    <strong
                                    ><a href="mailto:info@moonshotanimations.com"
                                    >Sending us an Email.</a
                                        ></strong
                                    >
                                </li>
                            </ul>
                            <p>
                                As soon as we receive your refund request, we will respond to it at our
                                earliest, once the required analysis is completed, we will initiate the
                                process.
                            </p>
                            <p>
                                After you have received your refund, you will not have any rights to any
                                designs submitted by Moonshot Animations, the information will be
                                submitted the Copyright Acquisition of the Government Copyright Agencies
                                to maintain legality.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default RefundPolicy;