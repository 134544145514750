import React from "react";
import { Modal } from "react-bootstrap";
const VideoModal = (props) => {
    const { show, onCancel, dataSrc } = props;
    const closeModel = () => {
        onCancel();
    }
    return (

        <Modal
            show={show}
            onHide={onCancel}
            className="video border-radius-none"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <div className="modal-dialog modal-dialog-centered modal-lg"> */}
            {/* <div className="modal-content rounded-0"> */}
            <div className="modal-body p-0">
                <button
                    type="button"
                    className="btn-close"
                    onClick={closeModel}
                >
                    <i className="bi bi-x-lg text-white"></i>
                </button>
                <iframe
                    width="100%"
                    src={dataSrc}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                    title="Storyboard_Reel"
                    className="iframevideo"
                ></iframe>
            </div>
            {/* </div> */}
            {/* </div> */}
        </Modal>
    )
}
export default VideoModal;