import React, { useState } from "react";
// import { send } from 'emailjs-com';
// import { Config } from "../../util/config";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import history from "../../history";
import stripeImg from "../../images/stripe.png";
import secureImg from "../../images/secure.png";
import Services from '../../services/index';

const CheckOutForm = (props) => {

    const [succeeded, setSucceeded] = useState(false)
    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [fname, setFname] = useState('')
    const [email, setEmail] = useState('')
    const [number, setPhone] = useState('')
    const stripe = useStripe()
    const elements = useElements()

    const {
        description,
        category,
        itemList,
        name,
        price
    } = props.data;

    const cardStyle = {
        style: {
            base: {
                iconColor: '#666EE8',
                color: "#fff",
                fontFamily: "Arial, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "18px",
                lineHeight: '36px',
                border: "1px solid #fff",
                "::placeholder": {
                    color: "#ffffff5e",
                },
            },
            invalid: {
                color: "red",
                iconColor: "#fa755a",
            },
        },
    }

    const handleChange = async event => {
        setDisabled(event.empty)
        setError(event.error ? event.error.message : "")
    }

    const handleSubmit = async ev => {
        ev.preventDefault()
        setProcessing(true)



        if (!fname && !email && !number) {
            setError("Must Provide Required Information")
            setProcessing(false)
            return
        }
        setDisabled(false)

        let secKey = ""

        //await fetch("http://localhost:8080/create-payment-intent", {
        await fetch("https://moonshotanimationspaymentapi.herokuapp.com/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                price: price * 100,
                items: { fullname: fname, email: email, phone: number, category: category, name: name, description: itemList.toString(), quantity: "1" },
                description: itemList.toString(),
                fullname: fname,
                email: email,
                phone: number,
                category: category,
                name: name
            }),
        })
            .then((res) => {
                const mydata = res.json();
                // console.log(mydata)
                return mydata

            })
            .then(data => {
                // console.log(data.clientSecret, "client secret ")
                // console.log(data, "this data")
                secKey = data.clientSecret
            })

        if (secKey) {
            const payload = await stripe.confirmCardPayment(secKey, {
                payment_method: {
                    card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),

                },
            })
            //console.log(payload.paymentIntent)

            if (payload.error) {
                setError(`Payment failed ${payload.error.message}`)
                setProcessing(false)
                sessionStorage.setItem("is_paid", JSON.stringify(false))
            } else {
                setError(null)
                setProcessing(false)
                setSucceeded(true)

                let res = await Services.paymentConfirmationEmail(
                    itemList,
                    price,
                    fname,
                    email,
                    number,
                    category,
                    name
                );

                var posts = JSON.parse(localStorage['payment_result'] || "[]");
                posts.push(payload.paymentIntent)
                localStorage['payment_result'] = JSON.stringify(posts);

                localStorage.removeItem("page")
                history.push("/payment-successful")
            }
        }

    }


    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="fullname"
                    placeholder="Your Full Name"
                    value={fname}
                    onChange={(e) => {
                        setFname(e.target.value)
                        if (fname && email && number) {
                            setDisabled(false)
                            setError(false)
                            setSucceeded(false)
                            setProcessing(false)
                        }
                    }}
                />

                <label htmlFor="fullname">Your Full Name</label>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="email"
                    className="form-control"
                    id="emailaddress"
                    name="emailaddress"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        if (fname && email && number) {
                            setDisabled(false)
                            setError(false)
                            setSucceeded(false)
                            setProcessing(false)
                        }
                    }}
                //onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="emailaddress">Your Email Address</label>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="phonenumber"
                    name="phonenumber"
                    placeholder="Your Phone Number"
                    value={number}
                    onChange={(e) => {
                        setPhone(e.target.value)
                        if (fname && email && number) {
                            setDisabled(false)
                            setError(false)
                            setSucceeded(false)
                            setProcessing(false)
                        }
                    }}
                //onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="phonenumber">Your Phone Number</label>
            </div>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label inline-label"
                >CARD NUMBER </label>
                {/* <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="0000-000-000"
                                        /> */}
                <CardNumberElement options={cardStyle}
                    onChange={handleChange}
                    id="card-number"
                />
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label inline-label"
                        >CARD EXPIRY </label>
                        {/* <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleFormControlInput1"
                                                    placeholder="0000-000-000"
                                                /> */}
                        <CardExpiryElement options={cardStyle}
                            onChange={handleChange}
                            id="card-expiry"
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label inline-label"
                        >CVC CODE </label>
                        {/* <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleFormControlInput1"
                                                    placeholder="0000-000-000"
                                                /> */}
                        <CardCvcElement options={cardStyle}
                            onChange={handleChange}
                            id="card-cvc"
                        />
                    </div>
                </div>
            </div>
            <div className="row priceBar">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mb-40">
                        <p>{name}</p>
                        <div>
                            <span>No ExclusiveTAX</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mb-3 text-end">
                        <p>US ${price}</p>
                        <span>----</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="border-bottom mb-3 opacity-50"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-6">
                    <div className="mb-3">
                        <p>You'll be charged Total</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-end">
                    <div className="mb-3">
                        <p>US ${price}</p>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                {/* <button className="btn btnGradientOne d-flex justify-content-center modalbutton">
                        CHECK OUT
                    </button> */
                }
                <button disabled={processing || disabled || succeeded || error} id="submit" className="btn btnGradientOne d-flex justify-content-center modalbutton">
                    <span id="button-text">
                        {/* {processing ? (
                            <span> Checkout <div className="spinner" id="spinner"></div></span>
                        ) : (
                            "Checkout"
                        )} */}
                        <span> Checkout
                            {processing && <div className="spinner" id="spinner"></div>}
                        </span>
                    </span>
                </button>

                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}

                {/* Show a success message upon completion */}
                {succeeded && (
                    <p className={succeeded ? "result-message" : "result-message hidden"}>
                        Payment Succeeded

                        {/* see the result in your
                                                <a href={`https://dashboard.stripe.com/test/payments`}>
                                                {" "}
                                                Stripe dashboard.
                                                </a>{" "}
                                                Refresh the page to pay again.
                                                */}
                    </p>
                )}

            </div>
            <div className="row stripe">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="text-end">
                        <p>We Accept:</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="">
                        <img src={stripeImg} alt="stripe icon" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="mb-3">
                        <h5><img src={secureImg} alt="secure icon" />Secure Payment</h5>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default CheckOutForm;