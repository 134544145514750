import React from "react";
import Slider from "react-slick";
import clientImg1 from "../../images/logo1.png";
import clientImg2 from "../../images/logo2.png";
import clientImg3 from "../../images/logo3.png";
import clientImg4 from "../../images/logo4.png";
import clientImg5 from "../../images/logo5.png";
import clientImg6 from "../../images/logo6.png";
import clientImg7 from "../../images/logo7.png";
import clientImg8 from "../../images/logo8.png";
import clientImg9 from "../../images/logo9.png";


const Clients = () => {
    var settings = {
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        pauseOnFocus: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    prevArrow: false,
                    nextArrow: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    return (
        <section className="CompanySlider overflow-hidden mb-53">
            <Slider {...settings} className="responsive" data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800">

                <div>
                    <img src={clientImg1} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg2} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg3} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg4} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg5} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg6} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg1} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg2} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg3} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg4} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg5} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg6} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg7} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg8} alt="Client Logo" />
                </div>
                <div>
                    <img src={clientImg9} alt="Client Logo" />
                </div>
            </Slider>
        </section>
    )
}
export default Clients;