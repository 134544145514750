import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import Packages from "../shared/packages";
import ContactForm from "../shared/contactForm";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const PricePackages = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Packages - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <Header />

            {/* Price Packages Section */}
            <Packages />

            {/* Contact Form Section */}
            <ContactForm />

            <Footer />


        </div>
    )
}
export default PricePackages;