import React, { useState } from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import Portfolio from "../shared/portfolioVideos";
import ContactForm from "../shared/contactForm";
import ServiceImg from "../../images/WhiteboardAnimations-OurServices.jpg";
import QueryModal from "../shared/queryModal";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const WhiteBoardAnimation = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);

    const openModel = () => {
        setQueryModalIsOpen(true);
    }

    const closeModel = () => {
        setQueryModalIsOpen(false);
    }

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Whiteboard Animation Video by Moonshotanimations.com</title>
                <meta name="description" content="Moonshot Animations provides whiteboard animation, explainer and animation videos for businesses of all sizes. Our videos are designed to help you engage with customers, build trust, and increase conversions. Let us take your business to the next level!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Whiteboard Animation Video by Moonshotanimations.com"
                />
                <meta
                    property="og:description"
                    content="Moonshot Animations provides whiteboard animation, explainer and animation videos for businesses of all sizes. Our videos are designed to help you engage with customers, build trust, and increase conversions. Let us take your business to the next level!"
                />
                <link rel="canonical" href="https://moonshotanimations.com/our-services/white-board-animation" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/our-services/white-board-animation" />
                <meta property="og:image" content={ogimg} />
            </Helmet>

            <QueryModal
                show={queryModalIsOpen}
                onCancel={closeModel}
            />

            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Our Services
                    </h2>
                </div>
            </section>

            {/* Our Services Section */}
            <section className="OurServiceSection mb-100 mt-50">
                <div className="container">
                    <div className="row">
                        <div
                            className="feature-card col-lg-12 col-xl-12 col-md-12"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                        >
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-xs-12"></div>
                                <div className="col-lg-5 col-md-5 col-xs-12">
                                    <h1 className="text-start">
                                        <strong>Whiteboard Animations</strong><br />
                                        that make you POP
                                    </h1>
                                    <p className="text-start">
                                        Want to make your consumer’s eyes widen? A whiteboard animation video instantly engages your audience. The sky is the limit!
                                    </p>
                                    <a
                                        className="btn btnGradientOne"
                                        onClick={() => openModel()}
                                        data-aos="fade-up"
                                        data-aos-duration="1200"
                                    > Get In Touch With Us </a>
                                </div>

                                <div className="col-lg-5 col-md-5 col-xs-12 feature-img flex-end">
                                    <img
                                        src={ServiceImg}
                                        alt="2d Animations"
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="col-lg-1 col-md-1 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Portfolio Section */}
            <section className="VideoSection bg-circle2 PrtfolioGrid mb-53">
                <div className="container-fluid">
                    <div className="text-center">
                        <h5
                            className="secondaryHeading"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="800"
                        >
                            Our Portfolio Speaks for Itself
                        </h5>
                    </div>
                    <Portfolio />
                </div>
            </section>

            {/* Contact Form Section */}
            <ContactForm />

            <Footer />

        </div>
    )
}
export default WhiteBoardAnimation;