import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">
            <Helmet>
                <title>Privacy Policy - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>
            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Privacy Policy
                    </h2>
                </div>
            </section>

            {/* Simple Content Section */}
            <section className="simpleContentSection overflow-hidden mb-53">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <h2>Privacy Policy Statement</h2>
                            <p>
                                Moonshot Animations is highly committed to keeping client’s personal
                                information safe and secure and we collect only essential and inevitable
                                information to process your order. We never share any of your
                                information with any individual outside our company in any way until you
                                agree to permit us for doing so.
                            </p>
                            <h3>Information Collection</h3>
                            <p>
                                We collect clients’ information via Email, Phone and Contact Us and
                                Order Form and it is protected as per privacy policy set by Moonshot
                                Animation. Clients’ information is never disclosed, shared or sold to
                                any third-party services.
                                <br />
                                Initially, the personal information we require is about clients’ name,
                                company’s name, billing address, email address, secondary email, phone
                                and fax numbers, order details and place of residence and some of which
                                is optional. Along with this, we need information in creative brief in
                                order to start working on your order.<br />
                                Additionally, collection of information includes opinions pertaining to
                                complaints, appreciation and comments about the product.<br />
                                Technical information which is collected and recorded includes your IP
                                address, browser version, operating system and the date and time when
                                you visit. Google Analytics or some similar tool or service is also used
                                for collecting this data.
                            </p>
                            <h3>Usage Of Collected Information</h3>
                            <p>
                                First of all, the information we collect helps us to understand exactly
                                what product you need and what special things would you require in it
                                and secondly the billing information is inevitable for payment
                                process.<br />
                                The email addresses are used for communication about order status,
                                comments and opinions and delivery of the finalized product.<br />
                                Moreover, we may send emails of update on the website, promotional
                                offers, launch of a new product/service and the latest news in the
                                industry.<br />
                                This information also helps us to improve our marketing strategies and
                                compile the record of the most selling products.
                            </p>
                            <h3>Privacy Of Payments</h3>
                            <p>
                                To make sure the practice of the best privacy for payments, we offer
                                acceptance from multiple payment options. The payment processor where
                                the clients put their billing information uses Secure Sockets Layer
                                (SSL) which is indicated in the domain as “https://”. Therefore, we
                                assure that there is no issue at all with the privacy of payment process
                                and billing information.
                            </p>
                            <h3>Confidentiality</h3>
                            <p>
                                Confidentiality is surely a serious concern and we are fully aware of
                                the fact and this is why we never compromise over the confidentiality of
                                our clients’ information.<br />
                                We use your personal information in order to keep in touch with you so
                                that we can have smooth communication about the order process.
                                Therefore, your entire information like name, billing address, e-mail
                                address, telephone number, fax, etc. is treated as confidential and
                                saved at a secure location which is accessible by the designated members
                                only ensuring that it stays safe and there is no threat of its being
                                stolen or hacked. We reassure that your personal information will never
                                be released to any third party in any circumstances until you allow or
                                we are forced by law.
                            </p>
                            <h3>Amendments</h3>
                            <p>
                                Moonshot Animations may amend its privacy policy for more strict
                                security concerns if needed. However, the entire amendments about
                                personal information security concerns will be declared with a prior
                                notice of the effective date of amended or updated privacy policies.
                            </p>
                            <h3>Conditions Of Information Disclosure</h3>
                            <p>
                                In the first place, it is our top priority to avoid the information
                                disclosure. However, the information disclosure can only take place if
                                we are to abide by the country law for judicial proceeding, court order,
                                or any other legal procedure.
                            </p>
                            <h3>Contact Us</h3>
                            <p>
                                If you have any confusion or concerns regarding our privacy policies,
                                you can contact us via our contact us form.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
export default PrivacyPolicy;