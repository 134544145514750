import React, { useState, useEffect } from "react";
import history from "../../history";
// import data from "../../data/packagesData.json";
import QueryModal from "../shared/queryModal";
import checkIcon from "../../images/check.png";

const Packages = () => {

    const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);

    const openModel = () => {
        setQueryModalIsOpen(true);
    }

    const closeModel = () => {
        setQueryModalIsOpen(false);
    }

    // const logoAnimation = [
    //     {
    //         name: "Starter Shot",
    //         category: "Logo Design Animation",
    //         price: "120",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Basic Shot",
    //         category: "Logo Design Animation",
    //         price: "250",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Premium Shot",
    //         category: "Logo Design Animation",
    //         price: "500",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Professional Shot",
    //         category: "Logo Design Animation",
    //         price: "800",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     }
    // ]
    // const twoDAnimation = [
    //     {
    //         name: "Starter Shot",
    //         category: "2D Animation",
    //         price: "200",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Basic Shot",
    //         category: "2D Animation",
    //         price: "500",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Premium Shot",
    //         category: "2D Animation",
    //         price: "750",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Professional Shot",
    //         category: "2D Animation",
    //         price: "900",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     }
    // ]
    // const threeDAnimation = [
    //     {
    //         name: "Starter Shot",
    //         category: "3D Animation",
    //         price: "400",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Basic Shot",
    //         category: "3D Animation",
    //         price: "800",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Premium Shot",
    //         category: "3D Animation",
    //         price: "1200",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Professional Shot",
    //         category: "3D Animation",
    //         price: "1400",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     }
    // ]
    // const motionGraphics = [
    //     {
    //         name: "Starter Shot",
    //         category: "Motion Graphics",
    //         price: "150",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Basic Shot",
    //         category: "Motion Graphics",
    //         price: "300",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Premium Shot",
    //         category: "Motion Graphics",
    //         price: "600",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Professional Shot",
    //         category: "Motion Graphics",
    //         price: "800",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     }
    // ]
    // const videoEditing = [
    //     {
    //         name: "Starter Shot",
    //         category: "Video Editing",
    //         price: "100",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Basic Shot",
    //         category: "Video Editing",
    //         price: "250",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Premium Shot",
    //         category: "Video Editing",
    //         price: "450",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     },
    //     {
    //         name: "Professional Shot",
    //         category: "Video Editing",
    //         price: "600",
    //         itemList: [
    //             "30s Duration",
    //             "Professional Script",
    //             "Storyboard to Final Video",
    //             "Voice Over - All Languages",
    //             "Dedicated Team",
    //             "24 / 7 Custom Support",
    //             "100 % Satisfaction"
    //         ]
    //     }
    // ]
    const [packageList1, setPackageList1] = useState([]);
    const [packageList2, setPackageList2] = useState([]);
    const [packageList3, setPackageList3] = useState([]);
    const [packageList4, setPackageList4] = useState([]);
    const [packageList5, setPackageList5] = useState([]);

    useEffect(() => {

        fetch('https://my-json-server.typicode.com/kamikhan12/moonshot-packages-data/db')
            .then(response => response.json())
            .then(json => {
                setPackageList1(json.logoAnimation)
                setPackageList2(json.twoDAnimation)
                setPackageList3(json.threeDAnimation)
                setPackageList4(json.motionGraphics)
                setPackageList5(json.videoEditing)
                // console.log(json)
            })

    }, []);

    const openCheckOut = (item) => {
        localStorage.setItem("page", "checkout")
        history.push({
            pathname: `/checkout`,
            state: {
                item: item
            }
        });
    }
    return (
        <section className="priceSection position-relative mb-53">
            <div className="container">
                <div className="text-center">
                    <h2
                        className="mainHeading varient2"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Price Packages
                    </h2>
                </div>
                <p
                    className="text-center"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                >
                    We’re happy to work on custom packages to meet your needs.
                </p>
                <nav className="priceTabs">
                    <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration="1200"
                    >
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="11"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                Logo Design/Animation
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="22"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                2D Animation
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="33"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                3D Animation
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="44"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact1"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                Whiteboard/Motion Graphics
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="55"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact2"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                Video Editing
                            </button>
                        </li>
                    </ul>
                </nav>
                <div
                    className="tab-content"
                    id="pills-tabContent"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                >
                    <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <div className="PackageContainerSlider">
                            {
                                packageList1.length && packageList1.map((item, i) => {
                                    return <div key={i}
                                        className="price-container slidepack"
                                        data-aos="fade-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="700"
                                    >
                                        <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                            {item.name}
                                        </h4>
                                        <h3 className="text-center">{item.price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        <ul>
                                            {
                                                item.itemList && item.itemList.length && item.itemList.map((list, j) => {
                                                    return <li key={j}>
                                                        <img src={checkIcon} alt="checkIcon" /><span className="ps-2"
                                                        > {list}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <div className="d-grid gap-2 mt-4">
                                            <a className="btn btnfull d-flex border-0"
                                                // onClick={() => openCheckOut(item)} 
                                                onClick={() => openModel()}
                                                >
                                                <span className="lh-lg mx-auto">Order Now</span>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <div className="PackageContainerSlider">


                            {
                                packageList2.length && packageList2.map((item, i) => {
                                    return <div key={i}
                                        className="price-container slidepack"
                                        data-aos="fade-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="700"
                                    >
                                        <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                            {item.name}
                                        </h4>
                                        <h3 className="text-center">{item.price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        <ul>
                                            {
                                                item.itemList && item.itemList.length && item.itemList.map((list, j) => {
                                                    return <li key={j}>
                                                        <img src={checkIcon} alt="checkIcon" /><span className="ps-2"
                                                        > {list}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <div className="d-grid gap-2 mt-4">
                                            <a className="btn btnfull d-flex border-0"
                                                // onClick={() => openCheckOut(item)}
                                                onClick={() => openModel()}
                                                >
                                                <span className="lh-lg mx-auto">Order Now</span>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                    >
                        <div className="PackageContainerSlider">

                            {
                                packageList3.length && packageList3.map((item, i) => {
                                    return <div key={i}
                                        className="price-container slidepack"
                                        data-aos="fade-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="700"
                                    >
                                        <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                            {item.name}
                                        </h4>
                                        <h3 className="text-center">{item.price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        <ul>
                                            {
                                                item.itemList && item.itemList.length && item.itemList.map((list, j) => {
                                                    return <li key={j}>
                                                        <img src={checkIcon} alt="checkIcon" /><span className="ps-2"
                                                        > {list}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <div className="d-grid gap-2 mt-4">
                                            <a className="btn btnfull d-flex border-0"
                                                // onClick={() => openCheckOut(item)}
                                                onClick={() => openModel()}
                                                >
                                                <span className="lh-lg mx-auto">Order Now</span>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-contact1"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                    >
                        <div className="PackageContainerSlider">

                            {
                                packageList4.length && packageList4.map((item, i) => {
                                    return <div key={i}
                                        className="price-container slidepack"
                                        data-aos="fade-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="700"
                                    >
                                        <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                            {item.name}
                                        </h4>
                                        <h3 className="text-center">{item.price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        <ul>
                                            {
                                                item.itemList && item.itemList.length && item.itemList.map((list, j) => {
                                                    return <li key={j}>
                                                        <img src={checkIcon} alt="checkIcon" /><span className="ps-2"
                                                        > {list}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <div className="d-grid gap-2 mt-4">
                                            <a className="btn btnfull d-flex border-0"
                                                // onClick={() => openCheckOut(item)}
                                                onClick={() => openModel()}
                                                >
                                                <span className="lh-lg mx-auto">Order Now</span>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-contact2"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                    >
                        <div className="PackageContainerSlider">

                            {
                                packageList5.length && packageList5.map((item, i) => {
                                    return <div key={i}
                                        className="price-container slidepack"
                                        data-aos="fade-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="700"
                                    >
                                        <h4 className="text-center fw-bolder mt-4 pb-2 border-white">
                                            {item.name}
                                        </h4>
                                        <h3 className="text-center">{item.price}<sup className="fw-normal fs-6">USD</sup></h3>
                                        <ul>
                                            {
                                                item.itemList && item.itemList.length && item.itemList.map((list, j) => {
                                                    return <li key={j}>
                                                        <img src={checkIcon} alt="checkIcon" /><span className="ps-2"
                                                        > {list}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        <div className="d-grid gap-2 mt-4">
                                            <a className="btn btnfull d-flex border-0"
                                                // onClick={() => openCheckOut(item)}
                                                onClick={() => openModel()}
                                                >
                                                <span className="lh-lg mx-auto">Order Now</span>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* <div className="price text-center">
                    <span data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                        <a href="link" className="btn btnGradientOne">View All Packages</a>
                    </span>
                </div> */}
                <QueryModal
                    show={queryModalIsOpen}
                    onCancel={closeModel}
                />
            </div>
        </section>
    )
}
export default Packages;