import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import ContactForm from "../shared/contactForm";
import FeatureImg1 from "../../images/script.svg";
import FeatureImg2 from "../../images/voiceover.svg";
import FeatureImg3 from "../../images/board.svg";
import FeatureImg4 from "../../images/victory.png";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const OurProcess = () => {
    var body = document.body;
    body.classList.add("innerPageTemp");

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    return (
        <div className="site-wrapper">

            <Helmet>
                <title>Our Process - Animated Video Production Company in USA</title>
                <meta name="description" content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!" />
                {/* OGTags */}
                <meta
                    property="og:title"
                    content="Animated Video Production Company in USA"
                />
                <meta
                    property="og:description"
                    content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
                />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content="Moonshot Animations" />
                <meta property="og:url" content="https://moonshotanimations.com/" />
                <meta property="og:image" content={ogimg} />
            </Helmet>

            <Header />

            {/* Inner Page Title Section */}
            <section className="InnerPageTitle overflow-hidden">
                <div className="text-center">
                    <h2
                        className="mainHeading varient3"
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="800"
                    >
                        Our Process
                    </h2>
                </div>
                <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="1000"
                >
                    We're happy to work on custom packages to meet your needs.
                </p>
            </section>

            {/* Our Process Section */}
            <section className="AboutSection mb-53 bg-circle3 text-left feature">
                <div className="container">
                    <div className="row">
                        <div
                            className="feature-card col-lg-12 col-xl-12 col-md-12"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className="text-start number">01</p>
                                    <h4 className="text-start">Makes You Heard</h4>
                                    <p className="text-start">
                                        The secret behind the best animation is its script. Our team of
                                        writers are experts in the industry and write unique and original
                                        scripts to convey your brand story.
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12 feature-img flex-end">
                                    <img src={FeatureImg1} alt="icon1" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="feature-card reverseOnMobile col-lg-12 col-xl-12 col-md-12"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12 feature-img flex-start">
                                    <img src={FeatureImg2} alt="icon2" className="img-fluid" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className="text-start number">02</p>
                                    <h4 className="text-start">Voice Over</h4>
                                    <p className="text-start">
                                        Next, we find a voice over artist that will resonate with your target
                                        audience and brand identity. A professional voice over artist makes
                                        the animations come alive!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="feature-card col-lg-12 col-xl-12 col-md-12"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1300"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className="text-start number">03</p>
                                    <h4 className="text-start">Storyboard Animation</h4>
                                    <p className="text-start">
                                        We make the first storyboard of the animation to visualize what it
                                        will look like. Our expert animators work hard to ensure it meets your
                                        brief and vision.
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12 feature-img flex-end">
                                    <img src={FeatureImg3} alt="icon3" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="feature-card reverseOnMobile col-lg-12 col-xl-12 col-md-12"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1300"
                        >
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12 feature-img flex-start">
                                    <img src={FeatureImg4} alt="icon4" className="img-fluid" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <p className="text-start number">04</p>
                                    <h4 className="text-start">Final Delivery</h4>
                                    <p className="text-start">
                                        Finally, we deliver the complete project to you and eagerly await your
                                        feedback. We want our creative process to be as collaborative as
                                        possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Form Section */}
            <ContactForm />

            <Footer />
        </div>
    )
}
export default OurProcess;