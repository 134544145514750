import React from "react";
import Slider from "react-slick";
import userImg1 from "../../images/user1.svg";
import userImg2 from "../../images/user2.svg";
import userImg3 from "../../images/user3.svg";
import userReview1 from "../../images/Reviews/Adnan.JPG";
import userReview2 from "../../images/Reviews/EHM.JPG";
import userReview3 from "../../images/Reviews/Jamie.JPG";
import userReview4 from "../../images/Reviews/Javid.JPG";
import userReview5 from "../../images/Reviews/Kristina.JPG";
import userReview6 from "../../images/Reviews/Marco.JPG";
import userReview7 from "../../images/Reviews/PatriciaBuck.JPG";
import userReview8 from "../../images/Reviews/Pedro.JPG";
import userReview9 from "../../images/Reviews/randy.JPG";
import userReview10 from "../../images/Reviews/Sophiya.JPG";


const Testimonial = () => {
    var setting = {
        dots: true,
        infinite: true,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        arrows: false,
        pauseOnHover: true,
        centerMode: true,
        variableWidth: true,
        speed: 800,
        cssEase: 'linear',
        slidesToShow: 2,
        centerPadding: '0px',
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    prevArrow: false,
                    nextArrow: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    centerMode: false,
                },
            },
        ],
    }
    return (
        <Slider className="sliderAb" {...setting}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1200"
        >
            <div className="ratting">
                <div className="card border-2 purple1">
                    <div className="card-holder">
                        {/* <div className="person">
                            <div className="d-flex flex-row align-item-center">
                                <div className="d-flex flex-row align-item-center">
                                    <div
                                        className="
													img-placeholder
													orange-bg1
													d-flex
													align-item-center
													justify-content-center
												"
                                    >
                                        <img src={userImg1} alt="userImg1" />
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-item-center">
                                    <h6
                                        className="
													clientName
													d-flex
													flex-column
													align-item-center
													justify-content-center
												"
                                    >
                                        Jared M
                                        <div className="designation">Marketing Manager</div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="star">
                            <p>
                                “Loved the 2D explainer the people at Moonshot made for us. It was exactly what we had envisioned to take our brand to the next level.”
                            </p>
                        </div> */}
                        <img src={userReview1} alt="userReview1" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple2">
                    <div className="card-holder">
                        {/* <div className="person">
                            <div className="d-flex flex-row align-item-center">
                                <div className="d-flex flex-row align-item-center">
                                    <div
                                        className="
													img-placeholder
													orange-bg2
													d-flex
													align-item-center
													justify-content-center
												"
                                    >
                                        <img src={userImg2} alt="userImg1" />
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-item-center">
                                    <h6
                                        className="
													clientName
													d-flex
													flex-column
													align-item-center
													justify-content-center
												"
                                    >
                                        Alicia P
                                        <div className="designation">Brand Manager</div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="star">
                            <p>
                                “As a small business, we were struggling to get better visibility online because the industry was so saturated. But thanks to Moonshot, we’ve gained a lot of traction and customer engagement. Super happy with the results!”
                            </p>
                        </div> */}
                        <img src={userReview2} alt="userReview2" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple3">
                    <div className="card-holder">
                        {/* <div className="person">
                            <div className="d-flex flex-row align-item-center">
                                <div className="d-flex flex-row align-item-center">
                                    <div
                                        className="
													img-placeholder
													orange-bg3
													d-flex
													align-item-center
													justify-content-center
												"
                                    >
                                        <img src={userImg3} alt="userImg1" />
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-item-center">
                                    <h6
                                        className="
													clientName
													d-flex
													flex-column
													align-item-center
													justify-content-center
												"
                                    >
                                        Max Truman
                                        <div className="designation">Data Visualizer</div>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="star">
                            <p>
                                “Whenever we need explainer videos, Moonshot is our go-to option without a doubt!”
                            </p>
                        </div> */}
                        <img src={userReview3} alt="userReview3" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple1">
                    <div className="card-holder">
                        <img src={userReview4} alt="userReview4" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple1">
                    <div className="card-holder">
                        <img src={userReview5} alt="userReview5" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple2">
                    <div className="card-holder">
                        <img src={userReview6} alt="userReview6" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple3">
                    <div className="card-holder">
                        <img src={userReview7} alt="userReview7" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple1">
                    <div className="card-holder">
                        <img src={userReview8} alt="userReview8" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple2">
                    <div className="card-holder">
                        <img src={userReview9} alt="userReview9" />
                    </div>
                </div>
            </div>
            <div className="ratting">
                <div className="card border-2 purple3">
                    <div className="card-holder">
                        <img src={userReview10} alt="userReview10" />
                    </div>
                </div>
            </div>
        </Slider>

    )
}
export default Testimonial;